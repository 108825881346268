import { HTMLAttributes } from 'react';
import { RequestAccessValues } from '../form';
import Success from './success.svg?react';
import { LoadingOverlay } from '@mantine/core';
import Step from './step';

const SuccessIndicator = ({ values }: { values: Partial<RequestAccessValues> }) => (
  <div className="tw-flex tw-flex-col tw-items-center">
    <Success />

    <h1 className="tw-mt-4 tw-font-sans tw-text-xl tw-font-semibold">Success</h1>

    <p className="tw-px-4 tw-pt-4">
      We'll revert back to you as soon as your account is ready. You will receive your invitation at:
    </p>

    <p className="tw-mb-10 tw-mt-2 tw-font-semibold">{values.email}</p>
  </div>
);

const ErrorIndicator = () => (
  <>
    <h1 className="tw-mt-4 tw-font-sans tw-text-xl tw-font-semibold tw-text-red-500">Something went wrong ... 😕</h1>

    <p className="tw-my-5 tw-px-12 tw-pt-4">Please check your internet connection and try again.</p>

    <p className="tw-mb-10 tw-px-12 tw-pt-4">
      If the issue persists, please reach out to us at <a href="mailto:hello@cradl.ai">hello@cradl.ai</a> or in the
      chat.
    </p>
  </>
);

export type RequestAccessFormStepProps = {
  success: boolean | undefined;
  currentValues: Partial<RequestAccessValues>;
} & HTMLAttributes<HTMLDivElement>;

export default function FinalStep({ success, currentValues, ...rest }: RequestAccessFormStepProps) {
  return (
    <Step {...rest}>
      <div className="tw-flex tw-flex-col tw-p-4 tw-text-center">
        <LoadingOverlay
          visible={success === undefined}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 5 }}
          loaderProps={{ color: 'black', type: 'dots' }}
        />

        {success && <SuccessIndicator values={currentValues} />}
        {success === false && <ErrorIndicator />}

        <a
          href="https://www.cradl.ai"
          className="tw-h-9 tw-w-full tw-font-sans tw-text-sm tw-font-semibold tw-text-slate-500"
        >
          Back to home page
        </a>
      </div>
    </Step>
  );
}
