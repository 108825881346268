import React from 'react';

type StepProps = React.HTMLAttributes<HTMLDivElement>;

export default function Step({ children, ...rest }: React.PropsWithChildren<StepProps>) {
  return (
    <div className="tw-p-2" {...rest}>
      {children}
    </div>
  );
}
