type HeaderProps = {
  title: string;
  subtitle: string;
  center?: boolean;
  size?: 'md' | 'lg';
};

export const Header = ({ title, subtitle, center, size }: HeaderProps) => (
  <div
    className="tw-mb-6 data-[center=true]:tw-justify-items-center data-[center=true]:tw-px-10 data-[center=true]:tw-text-center"
    data-center={center}
  >
    <p className="tw-text-xl tw-font-medium data-[size=lg]:tw-text-2xl" data-size={size}>
      {title}
    </p>
    <p className="tw-mt-2 tw-text-sm tw-text-slate-500">{subtitle}</p>
  </div>
);
