import { Button } from '@mantine/core';

export const NextAction = ({ text, withSkip }: { text: string; withSkip?: boolean }) => (
  <>
    <Button
      type="submit"
      unstyled
      classNames={{
        root: 'tw-mt-8 tw-w-full tw-rounded-lg tw-h-9 tw-text-sm tw-font-medium tw-font-sans tw-bg-gradient-to-b tw-from-blue-400 tw-to-blue-600',
      }}
    >
      {text}
    </Button>

    {withSkip && (
      <Button
        variant="borderless"
        type="submit"
        unstyled
        classNames={{ root: 'tw-w-full tw-h-9 tw-text-sm tw-font-medium tw-font-sans tw-text-slate-500' }}
      >
        Skip for now
      </Button>
    )}
  </>
);
