import { TextInput } from '@mantine/core';
import { HTMLAttributes } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';

import { RequestAccessValues } from '../form';
import { NextAction } from '../next-action';
import { Header } from '../header';
import Step from './step';
import LogoSmall from './logo-small.svg?react';

import blocklist from './email-blocklist.txt?raw';

export type RequestAccessFormStepProps = {
  currentValues: Partial<RequestAccessValues>;
  goToNextStep: (data: any) => void;
  goToPrevStep: (data: any) => void;
} & HTMLAttributes<HTMLDivElement>;

function validateEmail(value?: string): React.ReactNode {
  if (!/^\S+@\S+\.\S+$/.test(value!)) {
    return 'Invalid email.';
  }

  if (value) {
    const invalid = ['gmail', 'yahoo', 'hotmail', 'msn', ...blocklist.split('\n')];
    if (invalid.some((p) => value.toLowerCase().includes(p))) {
      return 'Please provide a work email.';
    }
  }

  return null;
}

export default function ({ goToNextStep, goToPrevStep, currentValues, ...rest }: RequestAccessFormStepProps) {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: currentValues,
    validate: {
      email: validateEmail,
      firstName: isNotEmpty('This field is mandatory'),
      lastName: isNotEmpty('This field is mandatory'),
    },
  });

  return (
    <Step {...rest} className="tw-flex tw-flex-col tw-items-center tw-gap-6">
      <div className="tw-rounded-lg tw-border tw-bg-gradient-to-b tw-from-white tw-to-slate-100 tw-p-2 tw-shadow-md">
        <LogoSmall width="2.2rem" height="2.2rem" />
      </div>

      <Header
        title="Request free access"
        subtitle="Tell us about your use case, and we’ll set up a free account for you!"
        center
        size="lg"
      />

      <form onSubmit={form.onSubmit(goToNextStep)}>
        <div className="tw-grid tw-grid-cols-2 tw-gap-5">
          <TextInput
            label="First name"
            placeholder="John"
            key={form.key('firstName')}
            {...form.getInputProps('firstName')}
            withAsterisk
          />
          <TextInput
            label="Last name"
            placeholder="Doe"
            key={form.key('lastName')}
            {...form.getInputProps('lastName')}
            withAsterisk
          />
        </div>

        <TextInput
          label="Work email"
          placeholder="john.doe@acme.com"
          className="tw-my-5"
          key={form.key('email')}
          {...form.getInputProps('email')}
          withAsterisk
        />

        <NextAction text="Continue -&gt;" />
      </form>
    </Step>
  );
}
