import React, { useCallback, useState } from 'react';

import { Logo } from '@components';

import onboardingBackground from './onboarding-bg.svg';
import { StepIndicator } from './step-indicator';
import ModelStep from './steps/fields-step';
import IntegrateStep from './steps/integrate-step';
import ContactInfoStep from './steps/contact-info-step';
import FinalStep from './steps/final-step';
import { Card } from '@mantine/core';
import { useTracker } from '@hooks';
import { BaseTracker } from '../../../../tracking';

const ENDPOINT = 'https://t33jbtp916.execute-api.eu-west-1.amazonaws.com/Prod/incoming';

export type RequestAccessValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  companyWebsite: string;
  useCase: string;
  documentType: string;
  fields: string[];
  source: string;
  destination: string;
  extraInfo?: string;
  email: string;
  files: File[];
  anonymousId: string;
};

const extractDomain = (email: string): string | null => {
  const regex = /^[^@]+@([^@]+\.[^@]+)$/;
  const match = email.match(regex);
  return match ? match[1] : null;
};

const getResponse = (data: Partial<RequestAccessValues>) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  companyName: data.companyName,
  companyWebsite: extractDomain(data.email!),
  documentType: data.documentType,
  source: data.source,
  destination: data.destination,
  fields: data.fields,
  email: data.email,
  extraInfo: data.extraInfo,
});

const sendTrackEvent = (tracker: BaseTracker, step: number, response: Partial<RequestAccessValues>) => {
  if (tracker) {
    const event = { 3: 'Onboarding Completed' }[step] || 'Onboarding Step Completed';

    console.log(`Sending tracking event ${event}`);
    if (import.meta.env.PROD) {
      tracker.track(event, { properties: { version: 2, step: step, response: getResponse(response) } });
    }
  }
};

export function RequestAccessForm() {
  const [formState, setFormState] = useState<Partial<RequestAccessValues>>({});
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState<boolean | undefined>(undefined);
  const [step, setStep] = useState<number>(0);
  const { tracker } = useTracker();

  const goToNextStep = (data: Partial<RequestAccessValues>) => {
    setFormState({ ...formState, ...data });
    setStep(step + 1);
    sendTrackEvent(tracker, step, formState);
  };

  const goToPrevStep = (data: any) => {
    setFormState({ ...formState, ...data });
    setStep(step - 1);
  };

  const finishSetup = useCallback(
    (data: Partial<RequestAccessValues>) => {
      const finalState = { ...formState, ...data };
      setFormState(finalState);
      setStep(step + 1);

      Promise.all(
        (finalState.files || []).map((f) => {
          const result = new Promise<{ name: string; content: string }>((resolve) => {
            const reader = new FileReader();

            reader.onload = async () => {
              const res = reader!.result! as string;
              const prefix = Date.now().toString();
              resolve({ name: `${prefix}-${f.name}`, content: res });
            };

            reader.readAsDataURL(f);
          });

          return result;
        })
      )
        .then((files) => {
          sendTrackEvent(tracker, step + 1, finalState);

          if (import.meta.env.PROD) {
            fetch(ENDPOINT, {
              method: 'POST',
              headers: { 'Content-Type': 'text/plain' },
              body: JSON.stringify({ ...getResponse(finalState), files: files }),
            })
              .then(() => setSubmittedSuccessfully(true))
              .catch(() => setSubmittedSuccessfully(false));
          } else {
            console.log('Payload:');
            console.log(getResponse(finalState));
            setSubmittedSuccessfully(true);
          }
        })
        .catch(() => setSubmittedSuccessfully(false));
    },
    [formState, step, tracker]
  );

  const commonProps = {
    goToNextStep: goToNextStep,
    goToPrevStep: goToPrevStep,
    currentValues: formState,
  };

  const steps: React.ReactNode[] = [
    <ContactInfoStep {...commonProps} />,
    <ModelStep {...commonProps} />,
    <IntegrateStep {...{ ...commonProps, goToNextStep: finishSetup }} />,
    <FinalStep success={submittedSuccessfully} currentValues={formState} />,
  ];

  return (
    <div className="tw-bg-[linear-gradient(white, #F4F3FA)] tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-pt-24">
      <div
        style={{
          backgroundImage: `url('${onboardingBackground}')`,
          backgroundPosition: '50% 50%',
        }}
        className="tw-pointer-events-none tw-absolute tw-top-[-50%] tw-h-full tw-w-full  tw-bg-no-repeat"
      />
      <div className="tw-z-10 tw-flex tw-flex-col tw-items-center">
        {step !== 0 && <Logo className="tw-h-10" />}

        <Card
          shadow="md"
          padding="lg"
          radius="lg"
          withBorder
          className="tw-mt-12 tw-flex tw-w-[32rem] tw-flex-col aria-[first=true]:tw-w-[28rem]"
          aria-first={step === 0}
        >
          <div className="tw-flex-1">{steps[step]}</div>

          <div className="flex-none">
            {0 < step && step < 3 && (
              <StepIndicator
                startStep={0}
                max={3}
                current={step}
                setCurrent={setStep}
                className="tw-mb-5 tw-mt-5 tw-w-full"
              />
            )}
          </div>
          {step === 0 && (
            <div className="tw-mt-6">
              <p className="tw-px-8 tw-text-center tw-text-xs tw-text-slate-400">
                By requesting, you agree to the processing of your personal data by Cradl AI as described in the 
                <a href="httsp://docs.cradl.ai/legal/privacy-policy">Privacy Policy</a>.
              </p>
            </div>
          )}
        </Card>

        {step === 0 && (
          <p className="tw-mt-8 tw-text-sm tw-font-medium tw-text-slate-500">
            Already have an account?{' '}
            <a href="https://app.cradl.ai/login" className="tw-font-semibold">
              Log in
            </a>
          </p>
        )}
      </div>
    </div>
  );
}
