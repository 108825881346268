import { useState, HTMLAttributes } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';

import { InputWrapper } from '@mantine/core';
import { RequestAccessValues } from '../form';
import { NextAction } from '../next-action';
import { Header } from '../header';
import Step from './step';

import InvoiceIcon from './document-icons/invoice.svg?react';
import ReceiptIcon from './document-icons/receipt.svg?react';
import BillofLadingIcon from './document-icons/bol.svg?react';
import PurchaseOrderIcon from './document-icons/po.svg?react';
import BankStatementIcon from './document-icons/bank-statement.svg?react';
import IdCardIcon from './document-icons/id-card.svg?react';
import ResumeIcon from './document-icons/resume.svg?react';
import CustomIcon from './document-icons/custom.svg?react';

type DocumentCardContainerProps = { children?: React.ReactNode };
const DocumentCardContainer = ({ children }: DocumentCardContainerProps) => (
  <div className="tw-grid tw-grid-cols-4 tw-items-center tw-justify-center tw-gap-4">{children}</div>
);

type DocumentType = {
  name: string;
};

type DocumentCardProps = {
  icon: React.ReactNode;
} & DocumentType &
  React.HTMLAttributes<HTMLDivElement>;

const DocumentCard = ({ icon, name, ...rest }: DocumentCardProps) => {
  return (
    <div
      className="tw-flex tw-h-[8rem] tw-flex-none tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-border tw-bg-[#F7F5F6] tw-p-2 tw-text-slate-700 aria-selected:tw-border-[#75e0a7] aria-selected:tw-bg-[#F6fef9] aria-selected:tw-text-[#085d3a]"
      {...rest}
    >
      <div className="tw-flex tw-flex-grow tw-items-center tw-justify-center">{icon}</div>
      <div className="tw-flex tw-grow-0 tw-flex-col tw-items-center tw-justify-center tw-px-5 tw-text-center tw-text-sm tw-font-medium">
        {name}
      </div>
    </div>
  );
};

type DocumentSelectProps = {
  documents: DocumentCardProps[];
  defaultValue?: string;
  onSelected: (value: string) => void;
};

const DocumentSelect = ({ documents, onSelected, defaultValue }: DocumentSelectProps) => {
  const [selected, setSelected] = useState<string | undefined>(defaultValue);

  const handleSelect = (name: string) => {
    setSelected(name);
    onSelected(name);
  };

  return (
    <DocumentCardContainer>
      {documents.map(({ name, icon }, i) => (
        <DocumentCard
          name={name}
          icon={icon}
          aria-selected={name === selected}
          onClick={() => handleSelect(name)}
          key={name}
        />
      ))}
    </DocumentCardContainer>
  );
};

export type RequestAccessFormStepValues = {
  useCase: string;
  documentCount: string;
  documentType: string;
  otherDocumentType: string;
};

export type RequestAccessFormStepProps = {
  goToNextStep: (data: any) => void;
  goToPrevStep: (data: any) => void;
  currentValues: Partial<RequestAccessValues>;
} & HTMLAttributes<HTMLDivElement>;

export default function ({ goToNextStep, goToPrevStep, currentValues, ...rest }: RequestAccessFormStepProps) {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: currentValues,
    validate: {
      documentType: isNotEmpty('Please select document type.'),
    },
  });

  const iconSize = { height: '5rem' };

  return (
    <Step {...rest}>
      <Header
        title="What are you looking to automate first?"
        subtitle="Select 1 document type for now. You can add more later."
      />

      <form onSubmit={form.onSubmit(goToNextStep)}>
        <InputWrapper
          withAsterisk
          error={form.errors.documentType}
          classNames={{ root: 'tw-flex tw-flex-col tw-gap-2', label: 'tw-text-xs tw-text-slate-500' }}
        >
          <DocumentSelect
            documents={[
              { name: 'Invoices', icon: <InvoiceIcon {...iconSize} /> },
              { name: 'Receipts', icon: <ReceiptIcon {...iconSize} /> },
              { name: 'Bills of Lading', icon: <BillofLadingIcon {...iconSize} /> },
              { name: 'Order Confirmations', icon: <PurchaseOrderIcon {...iconSize} /> },
              { name: 'ID Cards', icon: <IdCardIcon {...iconSize} /> },
              { name: 'Resumes', icon: <ResumeIcon {...iconSize} /> },
              { name: 'Bank Statements', icon: <BankStatementIcon {...iconSize} /> },
              { name: 'Custom', icon: <CustomIcon {...iconSize} /> },
            ]}
            onSelected={(value) => form.setValues({ documentType: value })}
            defaultValue={form.getValues().documentType}
          />
        </InputWrapper>

        <NextAction text="Next -&gt;" />
      </form>
    </Step>
  );
}
